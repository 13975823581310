<template>
    <AModal
        :visible="visible"
        :title="`${state.title} - User KAM`"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="60%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- no pegawai -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="No Pegawai"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        name="nopeg"
                        has-feedback>
                        <AInput
                            placeholder="no pegawai"
                            :disabled="item.readOnly"
                            v-model:value="form.nopeg" />
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- nama pegawai -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Nama Pengguna"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="fullname"
                        has-feedback>
                        <AInput
                            placeholder="nama pengguna"
                            :disabled="item.readOnly"
                            v-model:value="form.fullname" />
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- email -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Email"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="email"
                        has-feedback>
                        <AInput
                            placeholder="nama@domain.com"
                            :disabled="item.readOnly"
                            v-model:value="form.email" />
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- kata sandi -->
            <ARow v-if="!form.id" class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Kata Sandi"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="password"
                        has-feedback>
                        <AInputPassword
                            placeholder="kata sandi"
                            :disabled="item.readOnly"
                            v-model:value="form.password" />
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- konfirmasi kata sandi -->
            <ARow v-if="!form.id" class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Konfirmasi Kata Sandi"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="retype_password"
                        has-feedback>
                        <AInputPassword
                            placeholder="konfirmasi kata sandi"
                            :disabled="item.readOnly"
                            v-model:value="form.retype_password" />
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- phone -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Nomor Telepon"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="phone"
                        has-feedback>
                        <AInput
                            addon-before="+62"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            pattern="^[1-9][0-9]*$"
                            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                            placeholder="Ex : 81xxxxxxxxx"
                            v-model:value="form.phone"
                            :disabled="item.readOnly"
                            min="0"
                            maxlength="13" />
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- group mapping -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Group Mapping"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="group_role_id"
                        has-feedback>
                        <FilterGroupRole
                            :mode="null"
                            v-model:value="form.group_role_id"
                            :role="1260026"
                            :display-label="form.group_role_name"
                            :disabled="item.readOnly"
                            style="width:100%"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Status"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="active"
                        has-feedback>
                        <ARadioGroup
                            v-model:value="form.active"
                            :disabled="item.readOnly"
                            style="width:100%">
                            <ARadio :value="true">Aktif</ARadio>
                            <ARadio :value="false">Non Aktif</ARadio>
                        </ARadioGroup>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow v-if="!item.readOnly" class="form-row" type="flex" justify="end">
                <ACol>
                    <AFormItem>
                        <AButton
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</AButton>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>
    </AModal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterGroupRole from '@/components/filter/FilterGroupRole'
import { includes } from 'lodash'

export default defineComponent({
    components: {
        FilterGroupRole,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            nopeg: null,
            fullname: null,
            email: null,
            password: null,
            retype_password: null,
            phone: null,
            active: null,
            group_role_id: [],
            // optional
            group_role_name: '',
            readOnly: false,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat',
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                fullname: [
                    {
                        required: true,
                        message: "Nama pegawai tidak boleh kosong!",
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "Email tidak boleh kosong!",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "Kata sandi tidak boleh kosong!",
                    },
                    {
                        min: 8,
                        message: 'Kata Sandi minimal 8 karakter!',
                    },
                ],
                retype_password: [
                    {
                        required: true,
                        message: "Konfirmasi kata sandi tidak boleh kosong!",
                    },
                    {
                        validator: async (rule, value) => {
                            if (value !== form.password) {
                                return Promise.reject('Konfirmasi kata sandi harus sama dengan kata sandi!')
                            } else {
                                return Promise.resolve()
                            }
                        },
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: 'Nomor Telepon tidak boleh kosong!',
                    },
                    {
                        max: 12,
                        min: 0,
                        message: 'Maximal nomor telepon 12 angka',
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: 'Nomor Telepon tidak boleh memuat karakter selain angka',
                    },
                ],
                group_role_id: [
                    {
                        required: true,
                        type: 'number',
                        message: 'Group Mapping tidak boleh kosong!',
                    },
                ],
                active: [
                    {
                        required: true,
                        message: "Status tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post('/api/user-kam')
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
            } else {
                delete form.password
                delete form.retype_password

                form.put(`/api/user-kam/${form.id}`)
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
            }

        }

        const fetchByID = (id) => {
            apiClient.get(`/api/user-kam/${id}`)
                .then(({ data }) => {
                    form.nopeg = data.nopeg
                    form.fullname = data.fullname
                    form.email = data.email
                    form.phone = data.phone
                    form.active = data.active
                    form.group_role_id = data.group_role
                    // optional name text
                    form.group_role_name = data.role_name
                })
        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                fetchByID(props.item.id)
            }

            if (!props.item.readOnly) {
                state.title = !form.id ? 'Tambah' : 'Edit'
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            includes,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
